<template>
    <div class="policy-form-navigator questions_flyout">
        <h3>{{ company.abbreviation || company.name }}</h3>

        <div class="tab-content assessment-questions" id="nav-tabContent">
            <div class="tab-pane fade show active" id="nav-open" role="tabpanel" aria-labelledby="nav-open-tab">
                <div id="open-accordion">

                    <div class="card" v-for="policy in company.policies">
                        <div class="card-header p-0 section_header">
                            <h5 class="mb-0 d-flex align-items-center">
                                <button :class="`btn btn-link w-100 text-left text-uppercase ${activePolicy.id === policy.id ? 'font-bold' : ''}`" @click.prevent="editPolicy(policy.edit_route)">
                                    {{ policy.name }}
                                </button>
                            </h5>
                        </div>
                        <div class="questions_toggle collapse show" v-if="activePolicy.id === policy.id && Object.keys(selectedPolicyNavigation).length">
                            <div class="card-body p-0">
                                <ul class="list-group">
                                    <li :class="`list-group-item `" v-for="(navigationItemInputs) in selectedPolicyNavigation" v-if="selectedPolicyNavigation" :counter="navigationItemInputs[0].counter">
                                        <div class="navigation_label" v-if="navigationItemInputs[0].navigation_label">{{ navigationItemInputs[0].navigation_label }}</div>
                                        <div class="row">
                                            <div class="text-body col-12">
                                                {{ navigationItemInputs[0].label }}<span v-if="navigationItemInputs[0].type === 'phone'"> (enter numbers only)</span><br />
                                                <div class="question-inputs">
                                                    <template v-for="navigationInput in navigationItemInputs">
                                                        <PolicyFormNavigatorInput :navigation-input="navigationInput"></PolicyFormNavigatorInput>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                    <div class="card">
                        <div class="card-header p-0 section_header">
                            <h5 class="mb-0 d-flex align-items-center">
                                <button :class="`btn btn-link w-100 text-left text-uppercase font-bold`" @click.prevent="editPolicy(routes.create)">
                                    <i class="fa fa-plus"></i> &nbsp;Create a Policy
                                </button>
                            </h5>
                        </div>
                        <div class="questions_toggle collapse show" v-if="!Object.keys(activePolicy).length && Object.keys(selectedPolicyNavigation).length">
                            <div class="card-body p-0">
                                <ul class="list-group">
                                    <li :class="`list-group-item `" v-for="(navigationItemInputs) in selectedPolicyNavigation" v-if="selectedPolicyNavigation">
                                        <div class="row">
                                            <div class="text-body col-12">
                                                {{ selectedPolicy.fields.find(field=>field.slug === navigationItemInputs[0].slug).label }}<br />
                                                <div class="question-inputs">
                                                    <template v-for="navigationInput in navigationItemInputs">
                                                        <PolicyFormNavigatorInput :navigation-input="navigationInput"></PolicyFormNavigatorInput>
                                                    </template>
                                                </div>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'
    import PolicyFormNavigatorInput from './PolicyFormNavigatorInput'
    export default {
        name: "PolicyFormNavigator",
        props: [
            "routes",
            "policyType",
            "company",
            "activePolicy"
        ],
        components: {
            PolicyFormNavigatorInput
        },
        data: function(){
            return {
                focusedField: 0
            }
        },
        computed: {
            ...mapState('policyForm',[
                'selectedPolicy',
                'selectedPolicyNavigation'
            ])
        },
        methods:{
            editPolicy(editRoute){
                window.location = editRoute
            },
            focusField(fieldId){
                this.focusedField = fieldId
            },
            fieldIsDisplayed(field){
                console.log(document.getElementById('medcurityPolicyBody'))
                return true
            },
            fetchFieldLabel(firstItemInput){
                let policyField = this.selectedPolicy.fields.find(field=>field.slug === firstItemInput.originalSlug)
                if(!policyField){
                    console.log(firstItemInput)
                }
                return policyField ? policyField.label : '';
            }
        }
    }
</script>

<style scoped>
    .font-bold{
        font-weight: bold;
    }

    .questions_toggle ul li .text-body{
        font-size: 1.250em;
        color: #6D6E70 !important;
        font-weight: 400;
        padding: 9px 30px 9px 19px;
        display: block;
        line-height: 1.1;
    }
</style>
