<template>
    <div class="policy-custom-form">
        <div class="row">
            <div class="form-group col-12 col-md-10">
                <label for="template">Medcurity Policy Template</label>
                <select class="form-control w-100" name="template" id="template" :data-current-selection="policyTemplate" v-model="policyTemplate" @change="changeMaturityPolicy">
                    <option value="" hidden disabled selected>Select Template</option>
                    <option value="">No Template</option>
                    <option v-for="medcurityPolicy in medcurityPolicies" :value="medcurityPolicy.id">{{ medcurityPolicy.name }}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-8">
                <label for="name">Title</label>
                <input class="form-control" type="text" name="name" id="name" v-model="inputs.name" />
            </div>
        </div>
        <div class="row" v-if="previousPolicies && Object.keys(previousPolicies).length">
            <div class="form-group col-12 col-md-6">
                <label for="selected_previous_policy">Previous Policies</label>
                <select class="form-control w-100" name="selected_previous_policy" id="selected_previous_policy" v-model="selected_previous_policy">
                    <option v-for="(previousPolicyName, previousPolicyId) in previousPolicies" v-if="previousPolicyName.type != 'upload'" :value="previousPolicyId">{{ previousPolicyName.name }}</option>
                </select>
                <div v-if="selected_previous_policy">
                    <br>
                    <br>
                    <button id="loadvalues" class="btn btn-secondary" @click.prevent="loadPreviousValues">Insert Content From This Policy</button>
                </div>
            </div>
        </div>

        <div class="row" v-if="loadedScript">
            <div class="form-group col-12">
                <label for="policy">Policy</label>
                <vue-ckeditor name="policy" id="policy" v-model="inputs.policy" :config="config"></vue-ckeditor>
            </div>
        </div>


        <!-- Complete Assessment Modal -->
        <div class="modal fade" id="update-title-modal" tabindex="-1" role="dialog" aria-labelledby="modal-title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title" id="modal-title">Update Policy Title</h5>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input type="text" value="" name="update-title" id="update-title" style="padding: 3px 10px; width:100%;">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="updateTitle">Accept Title</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import VueCkeditor from 'vue-ckeditor2';
    import { mapState, mapMutations } from 'vuex'
    export default {
        name: "PolicyCustomInputs",
        components: {
            VueCkeditor
        },
        data: function(){
            return {
                loadedScript: false,
                policyTemplate: '',
                selected_previous_policy: '',
                config: {
                    toolbar: [
                        // ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
                        ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-'],
                        ['Undo', 'Redo', '-', 'Find', 'Replace', '-', 'SelectAll', 'RemoveFormat'],
                        ['Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'],
                        '/',
                        ['Bold', 'Italic', 'Strike', '-', 'Subscript', 'Superscript'],
                        ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Blockquote'],
                        ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                        ['Link', 'Unlink', 'Anchor'],
                        '/',
                        ['Format', 'FontSize'],
                        ['Maximize', 'ShowBlocks', '-', 'About']
                    ],
                    height: 600
                }
            }
        },
        computed: {
            ...mapState('policyForm',[
                'inputs',
                'routes',
                'medcurityPolicies',
                'statuses',
                'selectedPolicy',
                'previousPolicies',
                'renewFrequencies'
            ])
        },
        mounted(){
            this.populatePolicyTemplate()

            this.loadCkeditorScript()
        },
        methods: {
            ...mapMutations('policyForm',[
                'setCustomPolicyContent',
                'selectMedcurityPolicy',
                'setFieldsMatchedMedcurityPolicy'
            ]),
            populatePolicyTemplate(){
                if(!Object.keys(this.selectedPolicy).length){
                    return
                }
                this.policyTemplate = this.selectedPolicy.id
            },
            updateTitle(){
                this.inputs.name = $("#update-title").val();
                $('#update-title-modal').modal('hide');
            },
            loadPreviousValues(){
                let _this = this;
                if(this.selected_previous_policy){
                    axios.get(this.routes.base + this.selected_previous_policy + '/json')
                        .then(response => {
                            if(_this.inputs.policy){
                                if(confirm('Choosing a previous Policy will overwrite your current Policy in view.\n\r Would you like to continue?')){
                                    _this.setCustomPolicyContent(response.data.policy);
                                    _this.policyTemplate = '';
                                }
                            }else{
                                _this.setCustomPolicyContent(response.data.policy);
                                _this.policyTemplate = '';
                            }

                            let approvePolicyModal = $('#update-title-modal');

                            $("#update-title").val(response.data.name);
                            approvePolicyModal.modal('show');
                        });
                }
            },
            changeMaturityPolicy(e){
                const elValue = e.target.value,
                    currentSelection = e.target.dataset.currentSelection

                // if(!confirm('Choosing a new policy template will overwrite your current title and policy in view. \n\r Would you like to continue?')){
                //     this.policyTemplate = currentSelection
                //     return false
                // }

                e.target.dataset.currentSelection = elValue
                this.selectMedcurityPolicy(elValue)
                this.setFieldsMatchedMedcurityPolicy()

                let regex = /(%%)(.*?)(%%)+/g;
                this.inputs.policy = this.inputs.policy.replace(regex, '[Enter Text]');
                regex = /(\|\|)(.*?)(\|\|)+/g;
                this.inputs.policy = this.inputs.policy.replace(regex, '[Enter Text]');
            },
            loadCkeditorScript(){
                let ckeditorScript = document.createElement('script')
                ckeditorScript.setAttribute('src', 'https://cdn.ckeditor.com/4.6.2/full/ckeditor.js')
                document.head.appendChild(ckeditorScript)

                ckeditorScript.onload = ()=>{
                    this.loadedScript = true
                }
            }
        }
    }
</script>

<style scoped>

</style>
