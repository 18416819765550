<template>
    <div class="d-inline">
        <input v-if="navigationInput.type === 'text'" class="form-control"
               :data-target-id="navigationInput.id" :name="navigationInput.name" :type="navigationInput.type"
               :value="navigationInput.value" @input="updateOriginalElement">
        <input v-else-if="navigationInput.type === 'phone'" class="form-control" @keyup="phoneFilter"
               :data-target-id="navigationInput.id" :name="navigationInput.name" :type="navigationInput.type"
               :value="navigationInput.value" @input="updateOriginalElement">
        <div v-else-if="navigationInput.type === 'radio' || navigationInput.type === 'checkbox'">
            <label class="no-margin"><input :name="navigationInput.name" :type="navigationInput.type" :value="navigationInput.value"
                   :data-target-id="navigationInput.id" :checked="navigationInput.checked" @change="updateOriginalElement">&nbsp;{{ navigationInput.value }}</label>
        </div>
        <textarea v-else-if="navigationInput.type === 'textarea'" class="form-control" :data-target-id="navigationInput.id"
                  :name="navigationInput.name" @input="updateOriginalElement">{{ navigationInput.value }}</textarea>
        <select v-else-if="navigationInput.type === 'select'" class="form-control" :name="navigationInput.name"
                :data-target-id="navigationInput.id" @change="updateOriginalElement">
            <option v-for="navInputOption in navigationInput.options" :value="navInputOption.value"
                    :selected="navInputOption.selected" :hidden="navInputOption.hidden">{{ navInputOption.text }}</option>
        </select>
        <div v-else>
            {{ navigationInput.name }} - {{ navigationInput.type }}
        </div>
    </div>
</template>

<script>
    export default {
        name: "PolicyFormNavigatorInput",
        props: [
            "navigationInput"
        ],
        mounted() {
            // console.log(this.navigationInput)
        },
        methods: {
            updateOriginalElement(e){
                let originalInput = document.getElementById(e.target.dataset.targetId)

                if(!originalInput){
                    return
                }

                if(e.target.type === 'checkbox' || e.target.type === 'radio'){
                    originalInput.click();
                    originalInput.checked = e.target.checked
                }else{
                    originalInput.value = e.target.value;
                    originalInput.dispatchEvent(new Event('input', { 'bubbles': true }));
                    originalInput.click();

                }

            },
            phoneFilter(e){

                let input = e.target.value;

                input = input.replace(/\D/g, '');

                input = input.substring(0, 10);

                let size = input.length;
                if (size < 4) {
                    input = input;
                } else if (size < 7) {
                    input = input.substring(0, 3) + '-' + input.substring(3, 6);
                } else {
                    input = input.substring(0, 3) + '-' + input.substring(3, 6) + '-' + input.substring(6, 10);
                }
                e.target.value = input;
                this.updateOriginalElement(e);
            }
        }
    }
</script>

<style lang="scss" scoped>
    input[type="checkbox"] {
        display: inline-block;
    }
    input[type="checkbox"] + label::before{
        display: none;
    }
</style>
