//imports
// import moment from 'moment'

//State
const state = {
    inputs:{
        name: '',
        signed_as: 'ce',
        signed_as_third_party: 'ba',
        password: '',
        associates: [],
        renewal_frequency: '',
        ba_company_name: '',
        ba_contact_name: '',
        ba_contact_phone: '',
        baa: '',
        baa_with_fields: '',
    },
    oldValues: {},
    generalUser: 0,
    medcurityBaas: [],
    selectedBaa: {},
    previousBaas: {},
    selectedBaaNavigation: {},
    statuses: {
        development: 'In-Development',
        published: 'Published',
        removed: 'Removed'
    },
    renewFrequencies: {
        12: 'Annual',
        24: 'Two Years',
        36: 'Three Years'
    }
}


//Mutations
const mutations = {
    setMedcurityBaas(state, medcurityBaas){
        state.medcurityBaas = medcurityBaas
    },
    selectMedcurityBaa(state, baaId){
        state.selectedBaa = state.medcurityBaas.find((medcurityBaa) => medcurityBaa.id === parseInt(baaId)) || {}
    },
    setCustomBaaContent(state, baa){
        state.inputs.baa = baa;
    },
    selectMedcurityBaaNavigation(state, visibleInputs){
        state.selectedBaaNavigation = visibleInputs
    },
    setFieldsMatchedMedcurityBaa(){
        if(!state.inputs.name){
            state.inputs.name = state.selectedBaa.name || ''
        }
        state.inputs.baa = state.selectedBaa.baa || ''
        state.inputs.baa_with_fields = state.selectedBaa.with_fields || ''
    },
    setGeneralUser(state, generalUser) {
        state.generalUser = generalUser
    },
    setOldValues(state, oldValues) {
        state.oldValues = oldValues

        state.inputs.name = oldValues.name || ''
        state.inputs.password = oldValues.password || ''
        state.inputs.signed_as = oldValues.signed_as || 'ce'
        state.inputs.already_signed = oldValues.already_signed || '0'
        state.inputs.signed_as_third_party = oldValues.signed_as_third_party || 'ba'

        if(oldValues.associate_email || oldValues.associates){
            state.inputs.associates = oldValues.associate_email || oldValues.associates.map((associate)=>associate.email)
        }

        state.inputs.renewal_frequency = oldValues.renewal_frequency || ''
        state.inputs.ba_company_name = oldValues.ba_company_name || ''
        state.inputs.ba_contact_name = oldValues.ba_contact_name || ''
        state.inputs.ba_contact_phone = oldValues.ba_contact_phone || ''
        state.inputs.status = oldValues.status || ''
        state.inputs.baa = oldValues.baa || ''
	state.inputs.effective_date = oldValues.approved || ''
    },
    setOldInputValues(state, oldValues) {
        state.oldValues = oldValues
    },
    setPreviousBaas(state, previousBaas) {
        state.previousBaas = previousBaas;
    },
    setRoutes(state, routes) {
        state.routes = routes;
    }
}


//Actions
const actions = {
    setBaaTemplate({ commit }, medcurityBaaId) {
        commit('selectMedcurityBaa', medcurityBaaId)
    }
}


//export for the store/index.js file.
export default {
    namespaced: true,
    state,
    mutations,
    actions
}

