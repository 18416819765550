<template>
    <div class="policy-custom-form">
        <div class="row">
            <div class="form-group col-12 col-md-10">
                <label for="template">Medcurity BAA Template</label>
                <select class="form-control w-100" name="template" id="template" :data-current-selection="baaTemplate" v-model="baaTemplate" @change="changeMaturityBaa">
                    <option value="" hidden disabled selected>Select Template</option>
                    <option value="">No Template</option>
                    <option v-for="medcurityBaa in medcurityBaas" :value="medcurityBaa.id">{{ medcurityBaa.name }}</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-8">
                <label for="name">Title</label>
                <input class="form-control" type="text" name="name" id="name" v-model="inputs.name" />
            </div>
        </div>

        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="renewal_frequency">Review Frequency</label>
                <select class="form-control w-100" name="renewal_frequency" id="renewal_frequency" v-model="inputs.renewal_frequency">
                    <option value="" hidden disabled selected>Select Review Frequency</option>
                    <option v-for="(renewFrequencyLabel, renewFrequency) in renewFrequencies" :value="renewFrequency">{{ renewFrequencyLabel }}</option>
                </select>
            </div>
        </div>
        <div class="row" v-if="Object.keys(previousBaas).length">
            <div class="form-group col-12 col-md-6">
                <label for="renewal_frequency">Previous BAAs</label>
                <select class="form-control w-100" name="selected_previous_baa" id="selected_previous_baa" v-model="selected_previous_baa">
                    <option v-for="(previousBaaName, previousBaaId) in previousBaas" v-if="previousBaaName.type != 'upload'" :value="previousBaaId">{{ previousBaaName.name }}</option>
                </select>
                <div v-if="selected_previous_baa">
                    <br>
                    <br>
                    <button id="loadvalues" class="btn btn-secondary" @click.prevent="loadPreviousValues">Insert Content From This BAA</button>
                </div>
            </div>
        </div>


        <BaaMedcurityAssociates></BaaMedcurityAssociates>

        <div class="row" v-if="loadedScript">
            <div class="form-group col-12">
                <label for="baa">BAA</label>
                <vue-ckeditor name="baa" id="baa" v-model="inputs.baa" :config="config"></vue-ckeditor>
            </div>
        </div>

        <!-- Complete Assessment Modal -->
        <div class="modal fade" id="update-title-modal" tabindex="-1" role="dialog" aria-labelledby="modal-title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title" id="modal-title">Update BAA Title</h5>
                            <p style="font-size:11pt;">(We suggest including the BAA recipient's company name.)</p>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input type="text" value="" name="update-title" id="update-title" style="padding: 3px 10px; width:100%;">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="updateTitle">Accept Title</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import VueCkeditor from 'vue-ckeditor2';
    import { mapState, mapMutations } from 'vuex'
    import BaaMedcurityAssociates from './BaaMedcurityAssociates'
    export default {
        name: "BaaCustomInputs",
        components: {
            VueCkeditor,
            BaaMedcurityAssociates
        },
        data: function(){
            return {
                loadedScript: false,
                baaTemplate: '',
                selected_previous_baa: '',
                config: {
                    toolbar: [
                        // ['Bold', 'Italic', 'Underline', 'Strike', 'Subscript', 'Superscript']
                        ['Cut', 'Copy', 'Paste', 'PasteText', 'PasteFromWord', '-'],
                        ['Undo', 'Redo', '-', 'Find', 'Replace', '-', 'SelectAll', 'RemoveFormat'],
                        ['Table', 'HorizontalRule', 'SpecialChar', 'PageBreak'],
                        '/',
                        ['Bold', 'Italic', 'Strike', '-', 'Subscript', 'Superscript'],
                        ['NumberedList', 'BulletedList', '-', 'Outdent', 'Indent', 'Blockquote'],
                        ['JustifyLeft', 'JustifyCenter', 'JustifyRight'],
                        ['Link', 'Unlink', 'Anchor'],
                        '/',
                        ['Format', 'FontSize'],
                        ['Maximize', 'ShowBlocks', '-', 'About']
                    ],
                    height: 600
                }
            }
        },
        computed: {
            ...mapState('baaForm',[
                'inputs',
                'routes',
                'medcurityBaas',
                'statuses',
                'selectedBaa',
                'previousBaas',
                'renewFrequencies'
            ])
        },
        mounted(){
            this.populateBaaTemplate()

            this.selectMedcurityBaaNavigation({});
            this.loadCkeditorScript()
        },
        methods: {
            ...mapMutations('baaForm',[
                'setCustomBaaContent',
                'selectMedcurityBaa',
                'selectMedcurityBaaNavigation',
                'setFieldsMatchedMedcurityBaa'
            ]),
            populateBaaTemplate(){
                if(!Object.keys(this.selectedBaa).length){
                    return
                }
                this.baaTemplate = this.selectedBaa.id
            },
            updateTitle(){
                this.inputs.name = $("#update-title").val();
                $('#update-title-modal').modal('hide');
            },
            loadPreviousValues(){
                let _this = this;
                if(this.selected_previous_baa){
                    axios.get(this.routes.base + this.selected_previous_baa + '/json')
                        .then(response => {
                            if(_this.inputs.baa){
                                if(confirm('Choosing a previous BAA will overwrite your current BAA in view.\n\r Would you like to continue?')){
                                    _this.setCustomBaaContent(response.data.baa);
                                    _this.baaTemplate = '';
                                }
                            }else{
                                _this.setCustomBaaContent(response.data.baa);
                                _this.baaTemplate = '';
                            }

                            let approvePolicyModal = $('#update-title-modal');

                            $("#update-title").val(_this.inputs.name);
                            approvePolicyModal.modal('show');
                        });
                }
            },
            changeMaturityBaa(e){
                const elValue = e.target.value,
                    currentSelection = e.target.dataset.currentSelection

                if(this.inputs.title || this.inputs.baa){
                    if(!confirm('Choosing a new BAA template will overwrite your current title and BAA in view.\n\r Would you like to continue?')){
                        this.baaTemplate = currentSelection
                        return false
                    }
                }

                e.target.dataset.currentSelection = elValue
                this.selectMedcurityBaa(elValue)
                this.setFieldsMatchedMedcurityBaa()

            },
            loadCkeditorScript(){
                let ckeditorScript = document.createElement('script')
                ckeditorScript.setAttribute('src', 'https://cdn.ckeditor.com/4.6.2/full/ckeditor.js')
                document.head.appendChild(ckeditorScript)

                ckeditorScript.onload = ()=>{
                    this.loadedScript = true
                }
            }
        }
    }
</script>

<style scoped>

</style>
