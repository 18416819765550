<template>
    <div class="policy-medcurity-form">
        <!--<div class="row">-->
            <!--<div class="form-group col-12 col-md-6">-->
                <!--<label for="renewal_frequency">Renew Frequency</label>-->
            <!--</div>-->
        <!--</div>-->
        <div class="row">
            <div id="medcurityPolicySection" class="col-12 p-4">
                <div v-if="inputs.policy_with_fields" class="card w-100">
                    <div class="card-body" id="medcurityPolicyBody" ref="policyWithInputs">
                        <div class="" :id="selectedPolicy.element_id" v-html="inputs.policy_with_fields"></div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import VueMarkdown from 'vue-markdown'

    export default {
        name: "PolicyMedcurityInputs",
        props: [
            "medcurityScriptStack"
        ],
        components: {
            VueMarkdown
        },
        data: function(){
            return {
                policyTemplate: '',
                policyWithFieldsLoaded: false,
                canLoadScripts: true
            }
        },
        computed: {
            ...mapState('policyForm',[
                'inputs',
                'oldValues',
                'medcurityPolicies',
                'statuses',
                'selectedPolicy',
                'previousPolicies',
                'renewFrequencies',
            ])
        },
        mounted(){
            this.initInputs()
            this.observeMedcurityPolicy()
        },
        methods: {
            ...mapMutations('policyForm',[
                'selectMedcurityPolicy',
                'selectMedcurityPolicyNavigation',
                'setFieldsMatchedMedcurityPolicy'
            ]),
            populatePolicyTemplate(){
                if(!Object.keys(this.selectedPolicy).length){
                    return
                }
                this.policyTemplate = this.selectedPolicy.id
            },
            changeMaturityPolicy(e){
                this.canLoadScripts = true
                this.selectMedcurityPolicy(e.target.value)
                this.setFieldsMatchedMedcurityPolicy()

                setTimeout(()=>{
                    this.listenForMatchingChanges()
                    this.collectVisibleElements()

                },100)
            },
            loadPreviousValues(){
                let _this = this;
                if(this.selected_previous_policy){
                    axios.get(this.routes.base + this.selected_previous_policy + '/json')
                        .then(response => {
                            if(_this.inputs.policy){
                                if(confirm('Choosing a previous Policy will overwrite your current Policy in view.\n\r Would you like to continue?')){
                                    _this.setCustomPolicyContent(response.data.baa);
                                    _this.policyTemplate = '';
                                }
                            }else{
                                _this.setCustomPolicyContent(response.data.policy);
                                _this.policyTemplate = '';
                            }

                            let approvePolicyModal = $('#update-title-modal');

                            $("#update-title").val(_this.inputs.name);
                            approvePolicyModal.modal('show');
                        });
                }
            },
            initInputs(){
                    const oldTemplate = this.oldValues.template || this.oldValues.template_policy_id
                    this.policyTemplate = oldTemplate
                    this.selectMedcurityPolicy(oldTemplate)
                    this.setFieldsMatchedMedcurityPolicy()
                    this.loadScripts()

                    setTimeout(()=>{
                        this.fillOldPolicyInputs()
                        this.collectVisibleElements()
                    }, 100)
                    setInterval(()=>{
                        this.listenForMatchingChanges()
                        this.collectVisibleElements()
                        console.log('matchingcheck');
                    }, 2000)

            },
            fillOldPolicyInputs(){
                let listInputs = this.$refs.policyWithInputs.querySelectorAll('[data-type="list"]');

                listInputs.forEach((list)=>{
                    let found = true;
                    //start with 1 since we are cloning 0
                    let i = 1;
                    while(found && i < 15){
                        let values = this.oldValues;
                        let regex = new RegExp(list.getAttribute('data-first').replace('_','\_') + '\_' + i,"g");
                        let filtered = Object.keys(values).filter((values) => regex.test(values));
                        if(filtered.length){
                            let clonedListItem = list.childNodes[1].cloneNode(true);

                            let childIds = clonedListItem.querySelectorAll('[id]');
                            childIds.forEach((child)=>{
                                child.setAttribute('id', child.getAttribute('id') + '_' + i);
                            });

                            let childSlugs = clonedListItem.querySelectorAll('[data-slug]');
                            childSlugs.forEach((child)=>{
                                child.setAttribute('data-slug', child.getAttribute('data-slug') + '_' + i);
                            });

                            let childCounter = clonedListItem.querySelectorAll('[data-counter]');
                            childCounter.forEach((child)=>{
                                child.setAttribute('data-counter', i);
                            });

                            let childNames = clonedListItem.querySelectorAll('[name]');
                            childNames.forEach((child)=>{
                                let nameChunks = child.getAttribute('name').split('[');
                                nameChunks[0] = nameChunks[0] + '_' + i;
                                child.setAttribute('name', nameChunks.join('['));
                                child.setAttribute('onchange', 'checktrigger(this)');
                            });

                            let childTriggers = clonedListItem.querySelectorAll('[data-triggers]');
                            childTriggers.forEach((child)=>{
                                child.setAttribute('data-triggers', child.getAttribute('data-triggers') + '_' + i);
                            });

                            clonedListItem.setAttribute('data-current',i);

                            list.append(clonedListItem);

                            //set popper for clones
                            childIds.forEach((child)=>{

                                let newid = child.getAttribute('id');
                                let popperItem = document.getElementById('popper_' + newid);

                                if(popperItem !== null){
                                    window[newid].addEventListener('focus', function(){
                                        window['popperItem' + newid] = new window.Popper(
                                            window[newid],
                                            popperItem,
                                            {
                                                placement: 'top',
                                                modifiers: {
                                                    flip: {
                                                        behavior: ['left', 'bottom', 'top']
                                                    },
                                                    preventOverflow: {
                                                        boundariesElement: document.getElementById('medcurityPolicyBody'),
                                                    },
                                                }
                                            });
                                        window['popper_' + newid].classList.remove('d-none');
                                    });

                                    window[newid].addEventListener('focusout', function(){

                                        if(window['popperItem' + newid] !== null) {
                                            window['popperItem' + newid].destroy();
                                            popperItem.classList.add('d-none')
                                        }
                                    });
                                }
                            });

                        }else{
                            found = false;
                        }
                        list.setAttribute('data-current',i - 1);
                        i++;
                    }
                });



                let policyInputs = this.$refs.policyWithInputs.querySelectorAll('input, select, textarea')

                policyInputs.forEach((input)=>{
                    let inputName = input.name.replace('[]', ''),
                        inputType = input.getAttribute('type')

                    if(!this.oldValues[inputName]){
                        return
                    }

                    if(inputType && (inputType === 'checkbox' || inputType === 'radio')){
                        let explodedValues = this.oldValues[inputName].split('|')

                        if(explodedValues.indexOf(input.getAttribute('value')) >= 0){
                            input.checked = true
                            // Dispatch the change event for our policy-input.blade.php javascript.
                            input.dispatchEvent(new Event('change', { 'bubbles': true }))
                        }
                    }else{
                        input.value = this.oldValues[inputName]
                        // Dispatch the change event for our policy-input.blade.php javascript.
                        input.dispatchEvent(new Event('change', { 'bubbles': true }))
                    }
                })

            },
            observeMedcurityPolicy(){
                // Select the node that will be observed for mutations
                const targetNode = document.getElementById('medcurityPolicySection');

                // Options for the observer (which mutations to observe)
                const config = { attributes: true, childList: true, subtree: true };

                // Callback function to execute when mutations are observed
                const callback = (mutationsList, observer) => {
                    for(let mutation of mutationsList) {
                        if (mutation.type === 'childList') {

                            if(this.canLoadScripts){
                                this.loadScripts()
                            }

                            this.collectVisibleElements()

                            this.canLoadScripts = false
                        }
                    }
                };

                // Create an observer instance linked to the callback function
                const observer = new MutationObserver(callback);

                // Start observing the target node for configured mutations
                observer.observe(targetNode, config);
            },
            loadScripts(){
                eval(this.medcurityScriptStack)
            },
            /**
             * Listen for any matching changes. If one input changes, and has other inputs with matching slugs, then
             * those inputs should as well reflect the current inputs change.
             */
            listenForMatchingChanges(){
                let policyInputs = this.$refs.policyWithInputs.querySelectorAll('input, select, textarea'),
                    slugsMonitored = []

                if(!policyInputs){
                    return
                }

                policyInputs.forEach((input) => {
                    let currentSlug = input.dataset.slug
                    if(slugsMonitored.indexOf(currentSlug) >= 0){
                        //Already watching this slug for changes. Continue iteration.
                        return
                    }

                    let slugMatches = this.$refs.policyWithInputs.querySelectorAll('[data-slug="'+currentSlug+'"]')


                    slugMatches.forEach((slugMatchedInput) => {
                        let slugMatchedInputType = slugMatchedInput.getAttribute('type')
                        let slugMatchedInputTag = slugMatchedInput.tagName


                        if(slugMatchedInputType === 'checkbox' || slugMatchedInputType === 'radio'){

                            if(!$(slugMatchedInput).attr('hasevent')){
                                // Foreach checkbox style item, listen for a click.
                                slugMatchedInput.addEventListener('click', (e) => {

                                    slugMatches.forEach((matchedToChange) => {
                                        if(e.target.value !== matchedToChange.value){
                                            return
                                        }

                                        matchedToChange.checked = !!e.target.checked
                                        matchedToChange.dispatchEvent(new Event('change', { 'bubbles': true }))
                                    })
                                    this.collectVisibleElements()
                                })
                                $(slugMatchedInput).attr('hasevent',true);
                            }

                        }else if(slugMatchedInputTag === 'SELECT'){
                            if(!$(slugMatchedInput).attr('hasevent')){
                                //Microsoft Edge doesn't allow "input" events on selects, checkboxes or radio buttons
                                slugMatchedInput.addEventListener('click', (e) => {
                                    let eventInputValue = e.target.value
                                    slugMatches.forEach((matchedToChange) => {
                                        matchedToChange.value = eventInputValue
                                        matchedToChange.dispatchEvent(new Event('change', { 'bubbles': true }))
                                    })
                                    this.collectVisibleElements()
                                })
                                $(slugMatchedInput).attr('hasevent',true);
                            }
                        }else{

                            if(!$(slugMatchedInput).attr('hasevent')){
                                // Foreach item, listen for a input.
                                slugMatchedInput.addEventListener('input', (e) => {
                                    let eventInputValue = e.target.value
                                    slugMatches.forEach((matchedToChange) => {
                                        matchedToChange.value = eventInputValue
                                        matchedToChange.dispatchEvent(new Event('change', { 'bubbles': true }))
                                    })

                                    this.collectVisibleElements()
                                })
                                $(slugMatchedInput).attr('hasevent',true);
                            }
                        }


                        slugsMonitored.push(currentSlug)
                    })
                })
            },
            /**
             * Get all the visible inputs for the policy's side navigation.
             */
            collectVisibleElements(){
                let policyInputs = this.$refs.policyWithInputs.querySelectorAll('input, select, textarea'),
                    collectedInputs = {}

                policyInputs.forEach((input)=>{
                    if(input.offsetWidth < 1){
                        return
                    }

                    let filtered = _.filter(collectedInputs, function(value,key){

                        let found = false;
                        value.forEach(function(item){
                            if(item.orig_value == input.dataset.orig_value && item.slug == input.dataset.slug){
                                found = true;
                            }
                        });
                        if(found){
                            return value;
                            return key.startsWith(input.dataset.slug + "[");
                        }
                    });
                    if(filtered.length > 0) {
                        return
                    }

                    if(!collectedInputs[input.name]){
                        collectedInputs[input.name] = [];
                    }

                    let parsedOptions = []
                    if(input.tagName === "SELECT"){
                        Object.keys(input.options).forEach((optionKey)=>{
                            let newOption = {
                                text: input.options[optionKey].innerText,
                                value: input.options[optionKey].value,
                                selected: input.options[optionKey].selected,
                                hidden: input.options[optionKey].hidden,
                            }
                            parsedOptions.push(newOption)
                        })
                    }

                    let importantInputValues = {
                        id: input.getAttribute('id'),
                        name: input.name,
                        slug: input.dataset.slug,
                        label: input.dataset.label,
                        counter: input.dataset.counter,
                        navigation_label: input.dataset.navigation_label,
                        originalSlug: input.dataset.originalSlug,
                        type: input.getAttribute('type') || input.tagName.toLowerCase(),
                        value: input.value,
                        orig_value: input.dataset.orig_value,
                        options: parsedOptions,
                        checked: input.checked
                    }

                    collectedInputs[input.name].push(importantInputValues)

                })
                this.selectMedcurityPolicyNavigation(collectedInputs)
            },

        }
    }
</script>

<style lang="scss">

    #medcurityPolicyBody{
        .popper {
            background-color: #007DC1;
            margin-bottom: 5px;
            color: #ffffff;
            padding: 4px 12px;
            border-radius: 7px;
            font-weight: bold;
        }

         /*RESET STYLES FOR CKeditor content. */
        h1,h2,h3,h4,p {
            color: initial;
            font-weight: initial;
            letter-spacing: revert;
        }
        p {
            font-size: initial;
            margin-bottom: revert;
            line-height: revert;
        }
        li p {
            display: block;
            span{
                display: block;
            }
        }
        label {
            color: revert;
            font-size: revert;
            margin-left: 0;
            max-width: 95%;
            vertical-align: middle;
            line-height: 17px;
        }
        input, select{
            height: 28px;
        }
        input[type="radio"]{
            position: relative;
            top: 7px;
        }
        input[type="checkbox"] {
            display: inline-block;
            position: relative;
            top: 9px;
        }
        input[type="checkbox"] + label::before{
            display: none;
        }
        select{
            float: initial;
            display: inline-block;
            position: relative;
            top: 4px;
         }
    }

</style>
