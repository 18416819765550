//imports
import moment from 'moment'

//State
const state = {
    inputs:{
        name: '',
        renewal_frequency: 12,
        policy: '',
        policy_with_fields: '',
    },
    oldValues: {},
    policyType: 'standard',
    medcurityPolicies: [],
    selectedPolicies: {},
    previousBaas: {},
    selectedPolicyNavigation: {},
    statuses: {
        development: 'In-Development',
        published: 'Published',
        removed: 'Removed'
    },
    renewFrequencies: {
        12: 'Annual',
        24: 'Two Years',
        36: 'Three Years'
    },
    comments: [],
    getCommentsRoute: '',
    postCommentRoute: '',
    generalUser: 0,
}


//Mutations
const mutations = {
    setMedcurityPolicies(state, medcurityPolicies){
        state.medcurityPolicies = medcurityPolicies
    },
    selectMedcurityPolicy(state, policyId){
        state.selectedPolicy = state.medcurityPolicies.find((medcurityPolicy) => medcurityPolicy.id === parseInt(policyId)) || {}
    },
    setCustomPolicyContent(state, policy){
        state.inputs.policy = policy;
    },
    selectMedcurityPolicyNavigation(state, visibleInputs){
        state.selectedPolicyNavigation = visibleInputs
    },
    setFieldsMatchedMedcurityPolicy(){
        state.inputs.name = state.selectedPolicy.name || ''
        state.inputs.policy = state.selectedPolicy.policy || ''
        state.inputs.policy_with_fields = state.selectedPolicy.with_fields || ''
    },
    setGeneralUser(state, generalUser) {
        state.generalUser = generalUser
    },
    setPreviousPolicies(state, previousPolicies) {
        state.previousPolicies = previousPolicies;
    },
    setOldValues(state, oldValues) {
        state.oldValues = oldValues
        state.inputs.name = oldValues.name || ''
        state.inputs.renewal_frequency = parseInt(oldValues.renewal_frequency) || 12
        state.inputs.status = oldValues.status || ''
        if (oldValues.type === 'upload') {
            state.inputs.effective_date = oldValues.approved ? moment(oldValues.approved).format('YYYY-MM-DD') : ''
        }
        state.inputs.policy = oldValues.policy || ''
    },
    setComments(state, commentsPayload){
        state.comments = commentsPayload
    },
    setGetCommentsRoute(state, getCommentsRoute){
        state.getCommentsRoute = getCommentsRoute
    },
    setPostCommentRoute(state, getCommentsRoute){
        state.postCommentRoute = getCommentsRoute
    },
    setRoutes(state, routes) {
        state.routes = routes;
    }
}


//Actions
const actions = {
    setPolicyTemplate({ commit }, medcurityPolicyId) {
        commit('selectMedcurityPolicy', medcurityPolicyId)
    },
    getComments({ state, commit }){
        return axios.get(state.getCommentsRoute)
            .then((response) => response.data)
            .then((response) => {
                commit('setComments', response)
            })
            .catch((err) => {
                console.log(err)
                alert('Something went wrong while trying to retrieve comments.');
            })
    },
    postComment({ state, dispatch }, commentBody){
        return axios.post(state.postCommentRoute, { body: commentBody })
            .then((response) => response.data)
            .then((response)=>{
                dispatch('getComments')
            })
    },
    postCommentEdits({ dispatch }, payload){
        return axios.post(payload.route, { body: payload.body })
            .then((response) => response.data)
            .then((response)=>{
                dispatch('getComments')
            })
    },
    deleteComment({ dispatch }, payload){
        return axios.post(payload.route, {})
            .then((response) => response.data)
            .then((response)=>{
                dispatch('getComments')
            })
    },
}


//export for the store/index.js file.
export default {
    namespaced: true,
    state,
    mutations,
    actions
}

