<template>
    <div class="policy-form">
        <div class="row">
            <div class="col-12 col-md-4" id="policyNavWrap">
                <div id="policyNavSticky">
                    <BaaFormNavigator :routes="routes" :company="company" :activeBaa="activeBaa"></BaaFormNavigator>
                </div>
            </div>
            <div class="col-12 col-md-8 d-flex flex-column align-items-center">
                <section class="questions w-100">
                    <hr>
                    <div class="row mb-2">
                        <div class="col-12 d-flex">
                            <h4 class="flex-grow-0">{{ inputs.name }}</h4>
                            <div class="flex-grow-1">
                                <a :href="routes.cancel" class="btn btn-secondary float-right mt-2">Back</a>
                            </div>
                        </div>
                    </div>
                    <hr>
                    <form method="POST" :action="routes.store" class="mb-4" enctype="multipart/form-data" id="baaForm">
                        <input type="hidden" name="_token" :value="csrf_token">
                        <input type="hidden" name="previous_baa" :value="previousBaa">

                        <fieldset>
                            <legend>BAA Details:</legend>

                            <span role="alert" aria-hidden="true" class="baa-feedback text-success d-none"><strong >Saving...</strong></span>
                            <div class="row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="type">Choose type of BAA</label>
                                    <select class="form-control w-100" name="type" id="type" @change="changeType" v-model="templateType">
                                        <option value="" hidden disabled selected>Choose Type</option>
                                        <option v-for="(label, type) in baaTypes" :value="type">{{ label }}</option>
                                    </select>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="templateType">

                            <BaaCustomInputs v-if="templateType === 'custom'"></BaaCustomInputs>
                            <BaaUploadInputs v-else-if="templateType === 'upload'"></BaaUploadInputs>
                            <BaaMedcurityInputs v-else-if="templateType === 'medcurity'" :medcurity-script-stack="medcurityScriptStack"></BaaMedcurityInputs>
                        </fieldset>


                        <div class="row">
                            <div class="col-12 text-right">
                                <a :href="routes.cancel" class="btn btn-secondary mr-4">Cancel</a>
                                <button class="btn btn-primary px-4">Save</button>
                            </div>
                        </div>

                    </form>

                </section>

                <div class="row w-100" v-if="Object.keys(oldValues).length && Object.keys(baaRevisions).length">
                    <div class="col d-flex flex-column align-items-center">
                        <BaaRevisions :baa-revisions="baaRevisions"></BaaRevisions>
                    </div>
                </div>
            </div>
        </div>
        <div id="stickyStop"></div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import BaaFormNavigator from './BaaFormNavigator'
    import BaaCustomInputs from "./BaaCustomInputs";
    import BaaUploadInputs from "./BaaUploadInputs";
    import BaaMedcurityInputs from "./BaaMedcurityInputs";
    import BaaRevisions from "./BaaRevisions";

    export default {
        name: "BaaFormWizard",
        props: [
            "generalUser",
            "company",
            "medcurityBaasData",
            "medcurityScriptStack",
            "routes",
            "oldValues",
            "baaRevisions",
            "previousBaa",
            "previousBaas",
            "activeBaa"
        ],
        components:{
            BaaFormNavigator,
            BaaCustomInputs,
            BaaUploadInputs,
            BaaMedcurityInputs,
            BaaRevisions
        },
        data: function(){
            return {
                templateType: '',
                baaTypes: {
                    custom: "Custom BAA",
                    upload: "Upload a BAA",
                    medcurity: "Medcurity BAA",
                }
            }
        },
        computed:{
            ...mapState('baaForm',[
                'inputs'
            ]),
            csrf_token(){
                return document.head.querySelector('meta[name="csrf-token"]').content
            }
        },
        mounted(){
            this.initWizard()
        },
        methods: {
            ...mapMutations({
                setMedcurityBaas: 'baaForm/setMedcurityBaas',
                setOldValues: 'baaForm/setOldValues',
                setPreviousBaas: 'baaForm/setPreviousBaas',
                setRoutes: 'baaForm/setRoutes',
		setGeneralUser: 'baaForm/setGeneralUser'
            }),
            ...mapActions('baaForm', [
                'setBaaTemplate'
            ]),
            changeType(){
                this.setOldValues(this.oldValues)
            },
            initWizard(){
                this.setMedcurityBaas(this.medcurityBaasData)
                this.setOldValues(this.oldValues)
                this.setPreviousBaas(this.previousBaas)
                this.setRoutes(this.routes)
                this.setGeneralUser(this.generalUser)

                this.setBaaTemplate(this.oldValues.template_baa_id || this.oldValues.template)

                if(this.oldValues.type){
                    this.templateType = this.oldValues.type
                }
            }
        }
    }
</script>

<style scoped>

</style>
