<template>
    <div class="policy-upload-form">
        <div class="row">
            <div class="form-group col-12 col-md-8">
                <label for="name">Title</label>
                <input class="form-control" type="text" name="name" id="name" v-model="inputs.name" />
            </div>
        </div>

        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="effective_date">Policy Adopted Date</label>
                <input class="form-control" type="date" name="effective_date" id="effective_date" v-model="inputs.effective_date" />
            </div>
        </div>

        <div class="row">
            <div class="form-group col-6">
                <label for="uploaded_policy">Upload an existing Policy</label>
                <input class="form-file-input" type="file" name="uploaded_policy" id="uploaded_policy">
                <div class="note" style="font-style: italic; font-size:80%;">*Files must be under 8mb for use with our PolicyScan tool</div>
            </div>
        </div>

        <div class="row" v-if="Object.keys(oldValues).length && oldValues.publicFilePath">
            <div class="col-12">
                <label>Currently Uploaded Policy Document: <strong>{{ oldValues.original_filename }}</strong></label>
                <iframe :src="`http://docs.google.com/viewer?url=${encodeURI(oldValues.publicFilePath)}&embedded=true`"
                        style="width:100%; height: 750px;border: none;"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'

    export default {
        name: "PolicyUploadInputs",
        data: function(){
            return {
                policyTemplate: '',
            }
        },
        computed: {
            ...mapState('policyForm',[
                'inputs',
                'medcurityPolicies',
                'statuses',
                'selectedPolicy',
                'oldValues',
                'renewFrequencies'
            ])
        },
        mounted(){
            this.populatePolicyTemplate()
        },
        methods: {
            ...mapMutations('policyForm',[
                'selectMedcurityPolicy',
                'setFieldsMatchedMedcurityPolicy'
            ]),
            populatePolicyTemplate(){
                if(!Object.keys(this.selectedPolicy).length){
                    return
                }
                this.policyTemplate = this.selectedPolicy.id
            },
            changeMaturityPolicy(e){
                const elValue = e.target.value,
                    currentSelection = e.target.dataset.currentSelection

                if(this.inputs.title || this.inputs.policy){
                    if(!confirm('Choosing a new policy template will overwrite your current title and policy in view.\n\r Would you like to continue?')){
                        this.policyTemplate = currentSelection
                        return false
                    }
                }

                e.target.dataset.currentSelection = elValue
                this.selectMedcurityPolicy(elValue)
                this.setFieldsMatchedMedcurityPolicy()

            },
        }
    }
</script>

<style scoped>

</style>
