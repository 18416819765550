// vueApp
//window.Vue = require('vue');
import Vue from 'vue'

//Include vuex functionality.
import store from './store'
import PolicyFormWizard from './components/PolicyFormWizard.vue';
import BaaFormWizard from './components/BaaFormWizard.vue';


//Avoid trying to load the app if the container element doesn't exist.
if(document.getElementById('vueApp')){
    Vue.component('policy-form-wizard', PolicyFormWizard);
    Vue.component('baa-form-wizard', BaaFormWizard);

    const app = new Vue({
        el: '#vueApp',
        store
    });
}
