<template>
    <div class="policyRevisions w-100 questions">
        <h4>BAA Revisions</h4>
        <table class="table">
            <thead class="thead-light">
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Change Author</th>
                <th scope="col">Change Summary</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(baaRevision, revisionIndex) in limitedBaaRevisions">
                <th scope="row">{{ moment(baaRevision.created_at).format('M/D/YY h:mma') }}</th>
                <td>{{ baaRevision.revisor.full_name }}</td>
                <td>{{ baaRevision.summary }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "BaaRevisions",
        props: [
            "baaRevisions"
        ],
        computed:{
            limitedBaaRevisions(){
                if(!this.baaRevisions){
                    return []
                }

                return this.baaRevisions.slice(0,3)
            }
        },
        methods:{
            moment(datetime){
                return moment(datetime)
            }
        }
    }
</script>

<style scoped>

</style>
