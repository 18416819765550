import Vue from 'vue'
import 'es6-promise/auto'
import Vuex from 'vuex';

Vue.use(Vuex);

//vuex modules
import policyForm from './modules/policyForm'
import baaForm from './modules/baaForm'

export default new Vuex.Store({
    modules: {
        policyForm,
        baaForm
    }
})
