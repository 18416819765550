<template>
    <div class="policyRevisions w-100 questions">
        <h4>Policy Revisions</h4>
        <table class="table">
            <thead class="thead-light">
            <tr>
                <th scope="col">Date</th>
                <th scope="col">Change Author</th>
                <th scope="col">Change Summary</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="(policyRevision, revisionIndex) in limitedPolicyRevisions">
                <th scope="row">{{ moment(policyRevision.created_at).format('M/D/YY h:mma') }}</th>
                <td>{{ policyRevision.revisor.full_name }}</td>
                <td>{{ policyRevision.summary }}</td>
            </tr>
            </tbody>
        </table>
    </div>
</template>

<script>
    import moment from 'moment'
    export default {
        name: "PolicyRevisions",
        props: [
            "policyRevisions"
        ],
        computed:{
          limitedPolicyRevisions(){
              if(!this.policyRevisions){
                  return []
              }

              return this.policyRevisions.slice(0,3)
          }
        },
        methods:{
            moment(datetime){
                return moment(datetime)
            }
        }
    }
</script>

<style scoped>

</style>
