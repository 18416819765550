<template>
    <div class="policy-upload-form">
        <div class="row">
            <div class="form-group col-12 col-md-8">
                <label for="name">Title</label>
                <input class="form-control" type="text" name="name" id="name" v-model="inputs.name" />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="effective_date">BAA Adopted Date</label>
                <datepicker id="effective_date" :format="customFormatter" name="effective_date" v-model="inputs.effective_date"></datepicker>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="renewal_frequency">Review Frequency</label>
                <select class="form-control w-100" name="renewal_frequency" id="renewal_frequency" v-model="inputs.renewal_frequency">
                    <option value="" hidden disabled selected>Select Review Frequency</option>
                    <option v-for="(renewFrequencyLabel, renewFrequency) in renewFrequencies" :value="renewFrequency">{{ renewFrequencyLabel }}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-12 col-md-6">
                <input type="checkbox" value="1" :checked="alreadySigned" name="already_signed" id="already_signed" class="form-control">
                <label for="already_signed">Already Signed?</label>
                <div class="note">If there are already signatures on this BAA check this box to bypass the normal signature process</div>
            </div>
        </div>

        <BaaMedcurityAssociates></BaaMedcurityAssociates>

        <div class="row">
            <div class="form-group col-6">
                <label for="uploaded_baa">Upload an existing BAA</label>
                <input class="form-file-input" type="file" name="uploaded_baa" id="uploaded_baa">
            </div>
        </div>

        <div class="row" v-if="Object.keys(oldValues).length && oldValues.publicFilePath">
            <div class="col-12">
                <label>Currently Uploaded BAA Document: <strong>{{ oldValues.original_filename }}</strong></label>
                <iframe :src="`http://docs.google.com/viewer?url=${encodeURI(oldValues.publicFilePath)}&embedded=true`"
                        style="width:100%; height: 750px;border: none;"></iframe>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import BaaMedcurityAssociates from './BaaMedcurityAssociates'
    import Datepicker from 'vuejs-datepicker'
    import moment from 'moment'

    export default {
        name: "BaaUploadInputs",
        components: {
            BaaMedcurityAssociates,
            Datepicker
        },
        data: function(){
            return {
                baaTemplate: '',
                alreadySigned: 0,
            }
        },
        computed: {
            ...mapState('baaForm',[
                'inputs',
                'medcurityBaas',
                'statuses',
                'selectedBaa',
                'oldValues',
                'renewFrequencies'
            ])
        },
        mounted(){
            this.populateBaaTemplate();
            this.alreadySigned = this.inputs.already_signed;
            console.log(this.inputs);
            if(this.alreadySigned){

            }
        },
        methods: {
            ...mapMutations('baaForm',[
                'selectMedcurityBaa',
                'setFieldsMatchedMedcurityBaa'
            ]),
            customFormatter(date) {
                return moment(date).format('YYYY-MM-DD');
            },
            populateBaaTemplate(){
                if(!Object.keys(this.selectedBaa).length){
                    return
                }
                this.baaTemplate = this.selectedBaa.id
            },
            changeMaturityBaa(e){
                const elValue = e.target.value,
                    currentSelection = e.target.dataset.currentSelection

                if(this.inputs.title || this.inputs.baa){
                    if(!confirm('Choosing a new BAA template will overwrite your current title and BAA in view.\n\r Would you like to continue?')){
                        this.baaTemplate = currentSelection
                        return false
                    }
                }

                e.target.dataset.currentSelection = elValue
                this.selectMedcurityBaa(elValue)
                this.setFieldsMatchedMedcurityBaa()

            },
        }
    }
</script>

<style scoped>

</style>
