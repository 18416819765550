<template>
    <div class="policyComments w-100 questions">
        <h4>Comments</h4>
        <div id="leaveCommentSection" class="form-group">
            <div v-if="formErrors.length" class="alert alert-danger">
                <ul style="list-style: none;">
                    <li v-for="error in formErrors">{{ error }}</li>
                </ul>
            </div>
            <form class="form-group" @submit.prevent="submitComment">
                <label for="comment">Leave a Comment:</label>
                <textarea class="form-control" id="comment" rows="5" v-model="commentBody"></textarea>
                <button class="btn btn-primary float-right mt-2">Submit</button>
                <div class="clearfix"></div>
            </form>
        </div>
        <hr>
        <div id="commentsArea">
            <div v-if="!comments.length" class="border-bottom"><p>No comments at this time.</p></div>
            <div v-else class="comment border-bottom px-4 pb-4" v-for="comment in comments">
                <div class="d-flex">
                    <span class="flex-grow-1 text-secondary">{{ comment.user.full_name }} - {{ moment(comment.created_at).format('MM/DD/YYYY h:mma') }}
                        <span v-if="comment.editCommentRoute"> -
                            <a class="text-primary edit-comment-button" :href="comment.editCommentRoute" @click.prevent="editingComment = comment.id">Edit</a> |
                            <a class="text-danger delete-comment-button" :href="comment.deleteCommentRoute" @click.prevent="triggerDeleteComment">Delete</a></span>
                    </span>
                    <span v-if="moment(comment.updated_at).diff(moment(comment.created_at), 'minutes') > 0" class="flex-grow-0 text-secondary small text-right">edited: {{ moment(comment.updated_at).format('MM/DD/YYYY h:mma') }}</span>
                </div>
                <div v-if="editingComment !== comment.id" v-html="comment.body"></div>
                <div v-else class="form-group">
                    <div v-if="editFormErrors.length" class="alert alert-danger">
                        <ul style="list-style: none;">
                            <li v-for="error in editFormErrors">{{ error }}</li>
                        </ul>
                    </div>
                    <form @submit.prevent="submitCommentEdits">
                        <label for="editing_comment">Edit Comment</label>
                        <textarea class="form-control" id="editing_comment" rows="5" >{{ br2nl(comment.body) }}</textarea>
                        <button class="btn btn-primary float-right mt-2 ml-2">Submit</button> <button class="btn btn-secondary float-right mt-2" @click.prevent="editingComment = 0">Cancel</button>
                        <div class="clearfix"></div>
                    </form>
                </div>
            </div>
        </div>


    </div>
</template>

<script>
    import moment from 'moment'
    import { mapState, mapActions } from 'vuex'
    export default {
        name: "PolicyComments",
        data: function(){
            return {
                formErrors: [],
                editFormErrors: [],
                commentBody: '',
                editingComment: 0
            }
        },
        computed:{
            ...mapState('policyForm',[
                "comments"
            ])
        },
        methods: {
            ...mapActions({
                postComment: 'policyForm/postComment',
                postCommentEdits: 'policyForm/postCommentEdits',
                deleteComment: 'policyForm/deleteComment'
            }),
            submitComment(){
                this.postComment(this.commentBody)
                    .then(()=>{
                        this.commentBody = ''
                    })
                    .catch((error)=>{
                        if(!error || !error.response || !error.response.data || !error.response.data.errors){
                            alert('Something went wrong while trying to post a comment.');
                            return;
                        }

                        this.formErrors = error.response.data.errors.body
                    })
            },
            submitCommentEdits(){
                this.postCommentEdits({ route: this.comments.find(comment=>comment.id === this.editingComment).editCommentRoute , body: document.getElementById('editing_comment').value })
                    .then(() => {
                        document.getElementById('editing_comment').value = ''
                        this.editingComment = ''
                    })
                    .catch((error) => {
                        if (!error || !error.response || !error.response.data || !error.response.data.errors) {
                            alert('Something went wrong while trying to edit a comment.');
                            return;
                        }

                        this.editFormErrors = error.response.data.errors.body
                    })
            },
            triggerDeleteComment(e){
                if(confirm('Are you absolutely sure you would like to delete this comment?')){
                    this.deleteComment({ route: e.target.href })
                }
            },
            moment(datetime){
                return new moment(datetime);
            },
            br2nl(str) {
                return str.replace(/<br\s*\/?>/mg,"");
            }
        }
    }
</script>

<style scoped>

</style>
