<template>
    <div class="policy-form">
        <div class="row">
            <div class="col-12 col-md-4" id="policyNavWrap">
                <div id="policyNavSticky">
                    <PolicyFormNavigator :routes="routes" :company="company" :policyType="policyType" :activePolicy="activePolicy"></PolicyFormNavigator>
                </div>
            </div>
            <div class="col-12 col-md-8 d-flex flex-column align-items-center">
                <section class="questions w-100">
                    <form method="POST" :action="routes.store" class="mb-4" enctype="multipart/form-data" id="policyForm">
                        <hr>
                        <div class="row mb-2">
                            <div class="col-12 d-flex">
                                <h4 class="flex-grow-0">{{ inputs.name }}</h4>
                                <div class="flex-grow-1">
                                    <a :href="routes.cancel" class="btn btn-secondary float-right mt-2 ml-2">Back</a>
                                    <button id="sendForReviewButton" class="btn btn-primary float-right mt-2"
                                            @mouseover="showSendReviewPopper" @mouseout="hideSendReviewPopper" @click="sendForReview = true">Send for Review</button>
                                    <span class="popper d-none" id="sendForReviewPopper" v-html="sendForReviewPopperLabel"></span>
                                    <input v-if="sendForReview" type="hidden" name="send_for_review" value="true">
                                </div>
                            </div>
                        </div>
                        <hr>


                        <input type="hidden" name="_token" :value="csrf_token">
                        <input type="hidden" name="policy_type" :value="policyType">

                        <fieldset v-if="!templateType || templateType !== 'medcurity'">
                            <legend>Generate a Custom {{ policyType }} Policy</legend>

                            <div class="row">
                                <div class="form-group col-12 col-md-6">
                                    <label for="type">Choose type of Policy</label>
                                    <select class="form-control w-100" name="type" id="type" v-model="templateType">
                                        <option value="" hidden disabled selected>Choose Type</option>
                                        <option v-for="(label, type) in policyTypes" :value="type">{{ label }}</option>
                                    </select>
                                </div>
                            </div>
                        </fieldset>

                        <fieldset v-if="templateType">
                            <legend>{{ policyType }} Policy Details:</legend>

                            <span role="alert" aria-hidden="true" class="policy-feedback text-success d-none"><strong >Saving...</strong></span>
                            <table class="w-100 pb-1 policy-status-table">
                                <tbody>
                                <tr>
                                    <td></td>
                                    <td>
                                        <strong>Adopted:</strong> {{ oldValues.approved | dateFormat }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Approved by:</strong>
                                        <span v-if="oldValues.approved_name">{{ oldValues.approved_name }}</span>
                                        <span v-else>{{ oldValues.approved_by }}</span>
                                    </td>
                                    <td>
                                        <strong>Revised:</strong> {{ oldValues.updated_at | dateFormat }}
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <strong>Review:</strong>
                                        <select class="form-control" name="renewal_frequency" id="renewal_frequency"
                                                v-model="inputs.renewal_frequency">
                                            <option v-for="(renewFrequencyLabel, renewFrequency) in renewFrequencies"
                                                    :value="renewFrequency">{{ renewFrequencyLabel }}</option>
                                        </select>
                                    </td>
                                    <td>
                                        <strong>Reviewed:</strong> {{ oldValues.renewed | dateFormat }}
                                    </td>
                                </tr>
                                </tbody>
                            </table>

                            <PolicyCustomInputs v-if="templateType === 'custom'"></PolicyCustomInputs>
                            <PolicyUploadInputs v-else-if="templateType === 'upload'"></PolicyUploadInputs>
                            <PolicyMedcurityInputs v-else-if="templateType === 'medcurity'" :medcurity-script-stack="medcurityScriptStack"></PolicyMedcurityInputs>
                        </fieldset>


                        <div class="row">
                            <div class="col-12 text-right">
                                <a :href="routes.cancel" class="btn btn-secondary mr-4">Cancel</a>
                                <button class="btn btn-primary px-4">Save</button>
                            </div>
                        </div>

                    </form>

                </section>

                <div class="row w-100 mb-4" v-if="activePolicy && activePolicy.comments">
                    <div class="col">
                        <PolicyComments :comments="activePolicy.comments"></PolicyComments>
                    </div>
                </div>

                <div class="row w-100" v-if="Object.keys(oldValues).length && Object.keys(policyRevisions).length">
                    <div class="col d-flex flex-column align-items-center">
                        <PolicyRevisions :policy-revisions="policyRevisions"></PolicyRevisions>
                    </div>
                </div>
            </div>
        </div>
        <div id="stickyStop"></div>
    </div>
</template>

<script>
    import { mapState, mapMutations, mapActions } from 'vuex'
    import PolicyFormNavigator from './PolicyFormNavigator'
    import PolicyCustomInputs from "./PolicyCustomInputs";
    import PolicyUploadInputs from "./PolicyUploadInputs";
    import PolicyMedcurityInputs from "./PolicyMedcurityInputs";
    import PolicyRevisions from "./PolicyRevisions";
    import PolicyComments from "./PolicyComments";
    import moment from 'moment';

    export default {
        name: "PolicyFormWizard",
        props: [
            "generalUser",
            "company",
            "medcurityPoliciesData",
            "medcurityScriptStack",
            "routes",
            "policyType",
            "oldValues",
            "policyRevisions",
            "previousPolicies",
            "activePolicy"
        ],
        components:{
            PolicyFormNavigator,
            PolicyCustomInputs,
            PolicyUploadInputs,
            PolicyMedcurityInputs,
            PolicyRevisions,
            PolicyComments
        },
        filters: {
            dateFormat: function (value) {
                if (value) {
                    return moment(String(value)).format('MM/DD/YYYY')
                }
            },
            renewalPretty: function (value) {
                if (value == 12) {
                    return 'Annual'
                }else if(value == 24) {
                    return 'Two Years'
                }else if(value == 36) {
                    return 'Three Years'
                }
            }
        },
        data: function(){
            return {
                sendForReviewPopper: null,
                sendForReview: false,
                templateType: '',
                policyTypes: {
                    custom: "Custom Policy",
                    upload: "Upload a Policy"
                }
            }
        },
        computed:{
            ...mapState('policyForm',[
                'inputs',
                'renewFrequencies',
            ]),
            csrf_token(){
                return document.head.querySelector('meta[name="csrf-token"]').content
            },
            sendForReviewPopperLabel(){
                let lastSent = this.activePolicy && this.activePolicy.sent_for_review ? moment(this.activePolicy.sent_for_review).format('L') : ''
                lastSent = lastSent ? '<br /><br /><strong>Last Sent:</strong> '+lastSent : ''
                return 'Notify everyone (company Medcurity Admin or General users), by email, that this policy is ready for review and comment.'

            }
        },
        mounted(){
            this.initWizard()
        },
        methods: {
            ...mapMutations({
                setMedcurityPolicies: 'policyForm/setMedcurityPolicies',
                setOldValues: 'policyForm/setOldValues',
                setComments: 'policyForm/setComments',
                setRoutes: 'policyForm/setRoutes',
                setPreviousPolicies: 'policyForm/setPreviousPolicies',
                setGetCommentsRoute: 'policyForm/setGetCommentsRoute',
                setPostCommentRoute: 'policyForm/setPostCommentRoute',
                setGeneralUser: 'baaForm/setGeneralUser'
            }),
            ...mapActions('policyForm', [
                'setPolicyTemplate'
            ]),
            moment(datetime_string){
                return new moment(datetime_string)
            },
            initWizard(){
                this.setMedcurityPolicies(this.medcurityPoliciesData)
                this.setOldValues(this.oldValues)

                this.setPolicyTemplate(this.oldValues.template_policy_id || this.oldValues.template)

                if(this.oldValues.type){
                    this.templateType = this.oldValues.type
                }

                this.setPreviousPolicies(this.previousPolicies)
                this.setGeneralUser(this.generalUser)
                this.setRoutes(this.routes)

                if(this.activePolicy && this.activePolicy.comments){
                    this.setComments(this.activePolicy.comments)
                }

                if(this.routes.getComments){
                    this.setGetCommentsRoute(this.routes.getComments)
                }
                if(this.routes.postComment){
                    this.setPostCommentRoute(this.routes.postComment)
                }
            },
            showSendReviewPopper(e){
                const sendForReviewButton = document.getElementById('sendForReviewButton'),
                    sendForReviewPopper = document.getElementById('sendForReviewPopper')

                this.sendForReviewPopper = new window.Popper(sendForReviewButton, document.getElementById('sendForReviewPopper'), {
                    placement: 'bottom',
                    modifiers: {
                        flip: {
                            behavior: ['left', 'bottom', 'top']
                        },
                        preventOverflow: {
                            boundariesElement: document.getElementById('policyForm'),
                        },
                    },
                })

                sendForReviewPopper.classList.remove('d-none')
            },
            hideSendReviewPopper(e){
                this.sendForReviewPopper.destroy()
                document.getElementById('sendForReviewPopper').classList.add('d-none')
            }
        }
    }

</script>

<style scoped lang="scss">
    .popper {
        width: 200px;
        background-color: #0090df;
        margin-bottom: 5px;
        color: #ffffff;
        padding: 4px 12px;
        border-radius: 7px;
        font-weight: bold;
    }
</style>
