<template>
    <div id="baa-associates" v-if="!generalUser">
        <legend>Business Associate Details</legend>
        <div class="row">
            <div class="form-group col-12 col-md-4">
                <label for="name">BA Company Name</label>
                <input class="form-control" type="text" name="ba_company_name" id="ba_company_name" v-model="inputs.ba_company_name" />
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="name">BA Company Contact Name</label>
                <input class="form-control" type="text" name="ba_contact_name" id="ba_contact_name" v-model="inputs.ba_contact_name" />
            </div>
            <div class="form-group col-12 col-md-4">
                <label for="name">BA Company Contact Phone Number</label>
                <input class="form-control" type="tel" name="ba_contact_phone" id="ba_contact_phone" @input="acceptNumber()" v-model="inputs.ba_contact_phone"  placeholder="888 888 8888" maxlength="12" />
            </div>
        </div>

        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="signed_as">Are you a BA or CE?</label>
                <select class="form-control w-100" name="signed_as" id="signed_as" v-model="inputs.signed_as">
                    <option value="ce" selected>Covered Entity</option>
                    <option value="ba">Business Associate</option>
                </select>
            </div>
            <div class="form-group col-12 col-md-6">
                <label for="signed_as_third_party">Are you sending this to a BA or CE?</label>
                <select class="form-control w-100" name="signed_as_third_party" id="signed_as_third_party" v-model="inputs.signed_as_third_party">
                    <option value="ba" selected>Business Associate</option>
                    <option value="ce">Covered Entity</option>
                </select>
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="password">Master Password</label>
                <input class="form-control" type="text" id="password" name="password" v-model="inputs.password" />
            </div>
        </div>

        <div class="row" ref="associateEmailContainer">
            <div v-if="!inputs.associates.length" class="form-group col-12 col-md-6 associate-email">
                <label for="associate_email_1">Associate Email #<span>1</span></label>
                <div class="d-flex">
                    <input class="form-control flex-grow-0" type="email" id="associate_email_1" name="associate_email[]">
                    <button class="btn btn-danger flex-grow-1 delete-associate-email">&times;</button>
                </div>
            </div>
            <div v-else v-for="(associate, index) in inputs.associates" class="form-group col-12 col-md-6 associate-email">
                <label :for="`associate_email_${index + 1}`">Associate Email #<span>{{ index + 1 }}</span></label>
                <div class="d-flex">
                    <input class="form-control flex-grow-0" type="email" :id="`associate_email_${index + 1}`"
                           name="associate_email[]" :value="associate">
                    <button class="btn btn-danger flex-grow-1 delete-associate-email">&times;</button>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-12">
                <button class="btn btn-secondary" @click.prevent="addAssociateEmail"><i class="fa fa-envelope"></i>&nbsp;Add Email</button>
            </div>
        </div>
    </div>
</template>

<script>
    import { mapState } from 'vuex'

    export default {
        name: "BaaMedcurityAssociates",
        computed:{
            ...mapState('baaForm', [
                'inputs',
                'generalUser'
            ])
        },
        mounted(){
            console.log('generalUser: ' + this.generalUser);
            this.bindEvents()
        },
        methods:{
            acceptNumber() {
                console.log('acceptnumber');
                var x = this.inputs.ba_contact_phone.replace(/\D/g, '').match(/(\d{0,3})(\d{0,3})(\d{0,4})/);
                this.inputs.ba_contact_phone = !x[2] ? x[1] : x[1] + ' ' + x[2] + (x[3] ? '-' + x[3] : '');
            },
            bindEvents(){
                window.onload = ()=>{
                    window.$(document).on('click', 'button.delete-associate-email', this.removeAssociateEmail)
                }
            },
            addAssociateEmail(){
                let associateEmailInputs = document.getElementsByClassName('associate-email')

                if(!associateEmailInputs){
                    console.log('Missing associate email inputs.');
                    return;
                }

                let newAssociateEmail = associateEmailInputs[ associateEmailInputs.length - 1 ].cloneNode(true)
                let clonedLabel = newAssociateEmail.getElementsByTagName('label')[0],
                    clonedLabelSpan = clonedLabel.getElementsByTagName('span')[0],
                    clonedInput = newAssociateEmail.getElementsByTagName('input')[0]

                clonedLabelSpan.innerText = (parseInt(clonedLabelSpan.innerText) + 1).toString()

                let newId = clonedLabel.getAttribute('for').split('_')


                newId[ newId.length - 1 ] = clonedLabelSpan.innerText
                newId = newId.join('_')

                clonedLabel.setAttribute('for', newId)
                clonedInput.id = newId
                clonedInput.value = ''


                this.$refs.associateEmailContainer.append(newAssociateEmail)
            },
            removeAssociateEmail(e){
                e.preventDefault()

                if(document.getElementsByClassName('associate-email').length <= 1){
                    alert('One associate email field needs to be available.');
                    return;
                }

                e.target.closest('.associate-email').remove()
            }
        }
    }
</script>

<style scoped>

</style>
