<template>
    <div class="policy-medcurity-form">
        <div class="row" id="selectMedcurityBAA">
            <div class="form-group col-12 col-md-6">
                <label for="template">Medcurity BAA</label>
                <select class="form-control w-100" name="template" id="template" v-model="baaTemplate" @change="changeMedcurityBaa">
                    <option value="" hidden disabled selected>Select Template</option>
                    <option v-for="medcurityBaa in medcurityBaas" :value="medcurityBaa.id">{{ medcurityBaa.name }}</option>
                </select>
            </div>
        </div>

        <div class="row">
            <div class="form-group col-12 col-md-8">
                <label for="name">Title</label>
                <input class="form-control" type="text" name="name" id="name" v-model="inputs.name" />
            </div>
        </div>
        <div class="row">
            <div class="form-group col-12 col-md-6">
                <label for="renewal_frequency">Review Frequency</label>
                <select class="form-control w-100" name="renewal_frequency" id="renewal_frequency" v-model="inputs.renewal_frequency">
                    <option v-for="(renewFrequencyLabel, renewFrequency) in renewFrequencies" :value="renewFrequency">{{ renewFrequencyLabel }}</option>
                </select>
            </div>
        </div>
        <div class="row" v-if="Object.keys(previousBaas).length">
            <div class="form-group col-12 col-md-6">
                <label for="renewal_frequency">Previous BAAs</label>
                <select class="form-control w-100" name="selected_previous_baa" id="selected_previous_baa" v-model="selected_previous_baa">
                    <option v-for="(previousBaaName, previousBaaId) in previousBaas" v-if="previousBaaName.type == 'medcurity'" :value="previousBaaId">{{ previousBaaName.name }}</option>
                </select>
                <div v-if="selected_previous_baa">
                    <br>
                    <br>
                    <button id="loadvalues" class="btn btn-secondary" @click.prevent="loadPreviousValues">Insert Values From This BAA</button>
                </div>
            </div>
        </div>

        <BaaMedcurityAssociates></BaaMedcurityAssociates>

        <div class="row">
            <div id="medcurityBaaSection" class="col-12 p-4">
                <div v-if="inputs.baa_with_fields" class="card w-100">
                    <div class="card-body" id="medcurityBaaBody" ref="baaWithInputs">
                        <div class="" :id="selectedBaa.element_id" v-html="inputs.baa_with_fields"></div>
                    </div>
                </div>
            </div>
        </div>

        <!-- Complete Assessment Modal -->
        <div class="modal fade" id="update-title-modal" tabindex="-1" role="dialog" aria-labelledby="modal-title" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content">
                    <div class="modal-header">
                        <div>
                            <h5 class="modal-title" id="modal-title">Update BAA Title</h5>
                            <p style="font-size:14pt;">(We suggest including the BAA recipient's company name.)</p>
                        </div>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">&times;</span>
                        </button>
                    </div>
                    <div class="modal-body">
                        <input type="text" value="" name="update-title" id="update-title" style="padding: 3px 10px; width:100%;">
                    </div>
                    <div class="modal-footer">
                        <button type="button" class="btn btn-primary" @click.prevent="updateTitle">Accept Title</button>
                    </div>
                </div>
            </div>
        </div>
    </div>

</template>

<script>
    import { mapState, mapMutations } from 'vuex'
    import VueMarkdown from 'vue-markdown'
    import BaaMedcurityAssociates from './BaaMedcurityAssociates'

    export default {
        name: "BaaMedcurityInputs",
        props: [
            "medcurityScriptStack"
        ],
        components: {
            VueMarkdown,
            BaaMedcurityAssociates
        },
        data: function(){
            return {
                selected_previous_baa: '',
                baaTemplate: '',
                baaWithFieldsLoaded: false,
                canLoadScripts: true
            }
        },
        computed: {
            ...mapState('baaForm',[
                'inputs',
                'routes',
                'oldValues',
                'medcurityBaas',
                'statuses',
                'selectedBaa',
                'previousBaas',
                'activeBaa',
                'renewFrequencies',
            ])
        },
        mounted(){
            this.initInputs()
            this.observeMedcurityBaa()
        },
        methods: {
            ...mapMutations('baaForm',[
                'selectMedcurityBaa',
                'selectMedcurityBaaNavigation',
                'setFieldsMatchedMedcurityBaa',
                'setOldInputValues'
            ]),
            populateBaaTemplate(){
                if(!Object.keys(this.selectedBaa).length){
                    return
                }
                this.baaTemplate = this.selectedBaa.id
            },
            changeMedcurityBaa(e){
                this.canLoadScripts = true
                this.selectMedcurityBaa(e.target.value)
                this.setFieldsMatchedMedcurityBaa()

                setTimeout(()=>{
                    this.listenForMatchingChanges()
                    this.collectVisibleElements()

                },100)
            },
            updateTitle(){
                this.inputs.name = $("#update-title").val();
                $('#update-title-modal').modal('hide');
            },
            loadPreviousValues(){
                let _this = this;
                if(this.selected_previous_baa){
                    axios.get(this.routes.base + this.selected_previous_baa + '/json')
                        .then(response => {
                            _this.setOldInputValues(response.data);
                            _this.fillOldBaaInputs();
                            _this.collectVisibleElements();

                            let approvePolicyModal = $('#update-title-modal');

                            $("#update-title").val(_this.inputs.name);
                            approvePolicyModal.modal('show');

                        });
                }
            },
            initInputs(){
                if(this.oldValues && (this.oldValues.template || this.oldValues.template_baa_id)){
                    const oldTemplate = this.oldValues.template || this.oldValues.template_baa_id
                    this.baaTemplate = oldTemplate
                    this.selectMedcurityBaa(oldTemplate)
                    this.setFieldsMatchedMedcurityBaa()
                    this.loadScripts()
                    if(!this.inputs.renewal_frequency){
                        this.inputs.renewal_frequency = 12;
                    }

                    setTimeout(()=>{
                        this.fillOldBaaInputs()
                        this.listenForMatchingChanges()
                        this.collectVisibleElements()
                    }, 100)
                }else{
                    this.inputs.renewal_frequency = 12;
                }
                if(this.medcurityBaas.length == 1){
                    this.baaTemplate = this.medcurityBaas[0].id;
                    this.canLoadScripts = true
                    this.selectMedcurityBaa(this.baaTemplate)
                    this.setFieldsMatchedMedcurityBaa()

                    setTimeout(()=>{
                        this.listenForMatchingChanges()
                        this.collectVisibleElements()

                    },100)
                    document.getElementById('selectMedcurityBAA').style.display = 'none';
                }
            },
            fillOldBaaInputs(){

                    let listInputs = this.$refs.baaWithInputs.querySelectorAll('[data-type="list"]');

                    listInputs.forEach((list)=>{
                        let found = true;
                        //start with 1 since we are cloning 0
                        let i = 1;
                        while(found && i < 10){
                            let values = this.oldValues;
                            let regex = new RegExp(list.getAttribute('data-first').replace('_','\_') + '\_' + i,"g");
                            let filtered = Object.keys(values).filter((values) => regex.test(values));
                            if(filtered.length){
                                let clonedListItem = list.childNodes[1].cloneNode(true);

                                let childIds = clonedListItem.querySelectorAll('[id]');
                                childIds.forEach((child)=>{
                                    child.setAttribute('id', child.getAttribute('id') + '_' + i);
                                });

                                let childSlugs = clonedListItem.querySelectorAll('[data-slug]');
                                childSlugs.forEach((child)=>{
                                    child.setAttribute('data-slug', child.getAttribute('data-slug') + '_' + i);
                                });

                                let childNames = clonedListItem.querySelectorAll('[name]');
                                childNames.forEach((child)=>{
                                    let nameChunks = child.getAttribute('name').split('[');
                                    nameChunks[0] = nameChunks[0] + '_' + i;
                                    child.setAttribute('name', nameChunks.join('['));
                                    child.setAttribute('onchange', 'checktrigger(this)');
                                });

                                let childTriggers = clonedListItem.querySelectorAll('[data-triggers]');
                                childTriggers.forEach((child)=>{
                                    child.setAttribute('data-triggers', child.getAttribute('data-triggers') + '_' + i);
                                });

                                clonedListItem.setAttribute('data-current',i);

                                list.append(clonedListItem);

                                //set popper for clones
                                childIds.forEach((child)=>{

                                    let newid = child.getAttribute('id');
                                    let popperItem = document.getElementById('popper_' + newid);

                                    if(popperItem !== null){
                                        window[newid].addEventListener('focus', function(){
                                            window['popperItem' + newid] = new window.Popper(
                                                window[newid],
                                                popperItem,
                                                {
                                                    placement: 'top',
                                                    modifiers: {
                                                        flip: {
                                                            behavior: ['left', 'bottom', 'top']
                                                        },
                                                        preventOverflow: {
                                                            boundariesElement: document.getElementById('medcurityBaaBody'),
                                                        },
                                                    }
                                                });
                                            window['popper_' + newid].classList.remove('d-none');
                                        });

                                        window[newid].addEventListener('focusout', function(){

                                        if(window['popperItem' + newid] !== null) {
                                            window['popperItem' + newid].destroy();
                                            popperItem.classList.add('d-none')
                                        }
                                    });
                                }
                            });

                            }else{
                                found = false;
                            }
                            list.setAttribute('data-current',i - 1);
                            i++;
                        }
                    });



                let baaInputs = this.$refs.baaWithInputs.querySelectorAll('input, select, textarea')

                baaInputs.forEach((input)=>{
                    let inputName = input.name.replace('[]', ''),
                        inputType = input.getAttribute('type')
                    if(!this.oldValues[inputName]){
                        return
                    }

                    if(inputType && (inputType === 'checkbox' || inputType === 'radio')){
                        let explodedValues = this.oldValues[inputName].split('|')

                        if(explodedValues.indexOf(input.getAttribute('value')) >= 0){
                            input.checked = true
                            // Dispatch the change event for our policy-input.blade.php javascript.
                            input.dispatchEvent(new Event('change', { 'bubbles': true }))
                        }
                    }else{
                        input.value = this.oldValues[inputName]
                        // Dispatch the change event for our policy-input.blade.php javascript.
                        input.dispatchEvent(new Event('change', { 'bubbles': true }))
                    }
                })

            },
            observeMedcurityBaa(){
                // Select the node that will be observed for mutations
                const targetNode = document.getElementById('medcurityBaaSection');

                // Options for the observer (which mutations to observe)
                const config = { attributes: true, childList: true, subtree: true };

                // Callback function to execute when mutations are observed
                const callback = (mutationsList, observer) => {
                    for(let mutation of mutationsList) {
                        if (mutation.type === 'childList') {
                            console.log('A child node has been added or removed.');

                            if(this.canLoadScripts){
                                this.loadScripts()
                            }

                            this.collectVisibleElements()

                            this.canLoadScripts = false
                        }
                    }
                };

                // Create an observer instance linked to the callback function
                const observer = new MutationObserver(callback);

                // Start observing the target node for configured mutations
                observer.observe(targetNode, config);
            },
            loadScripts(){
                eval(this.medcurityScriptStack)
            },
            /**
             * Listen for any matching changes. If one input changes, and has other inputs with matching slugs, then
             * those inputs should as well reflect the current inputs change.
             */
            listenForMatchingChanges(){
                let baaInputs = this.$refs.baaWithInputs.querySelectorAll('input, select, textarea'),
                    slugsMonitored = []

                if(!baaInputs){
                    return
                }

                baaInputs.forEach((input) => {
                    let currentSlug = input.dataset.slug
                    if(slugsMonitored.indexOf(currentSlug) >= 0){
                        //Already watching this slug for changes. Continue iteration.
                        return
                    }

                    let slugMatches = this.$refs.baaWithInputs.querySelectorAll('[data-slug="'+currentSlug+'"]')


                    slugMatches.forEach((slugMatchedInput) => {
                        let slugMatchedInputType = slugMatchedInput.getAttribute('type')
                        let slugMatchedInputTag = slugMatchedInput.tagName



                        if(slugMatchedInputType === 'checkbox' || slugMatchedInputType === 'radio'){

                            // Foreach checkbox style item, listen for a click.
                            slugMatchedInput.addEventListener('click', (e) => {

                                slugMatches.forEach((matchedToChange) => {
                                    if(e.target.value !== matchedToChange.value){
                                        return
                                    }

                                    matchedToChange.checked = !!e.target.checked
                                    matchedToChange.dispatchEvent(new Event('change', { 'bubbles': true }))
                                })
                                this.collectVisibleElements()
                            })

                        }else if(slugMatchedInputTag === 'SELECT'){
                            //Microsoft Edge doesn't allow "input" events on selects, checkboxes or radio buttons
                            slugMatchedInput.addEventListener('click', (e) => {
                                let eventInputValue = e.target.value
                                slugMatches.forEach((matchedToChange) => {
                                    matchedToChange.value = eventInputValue
                                    matchedToChange.dispatchEvent(new Event('change', { 'bubbles': true }))
                                })
                                this.collectVisibleElements()
                            })
                        }else{

                            // Foreach item, listen for a input.
                            slugMatchedInput.addEventListener('input', (e) => {
                                let eventInputValue = e.target.value

                                slugMatches.forEach((matchedToChange) => {
                                    matchedToChange.value = eventInputValue
                                    matchedToChange.dispatchEvent(new Event('change', { 'bubbles': true }))
                                })

                                this.collectVisibleElements()
                            })
                        }


                        slugsMonitored.push(currentSlug)
                    })
                })
            },
            /**
             * Get all the visible inputs for the policy's side navigation.
             */
            collectVisibleElements(){
                let baaInputs = this.$refs.baaWithInputs.querySelectorAll('input, select, textarea'),
                    collectedInputs = {}

                baaInputs.forEach((input)=>{
                    if(input.offsetWidth < 1){
                        return
                    }

                    if(!collectedInputs[input.name]){
                        collectedInputs[input.name] = [];
                    }

                    let parsedOptions = []
                    if(input.tagName === "SELECT"){
                        Object.keys(input.options).forEach((optionKey)=>{
                            let newOption = {
                                text: input.options[optionKey].innerText,
                                value: input.options[optionKey].value,
                                selected: input.options[optionKey].selected,
                                hidden: input.options[optionKey].hidden,
                            }
                            parsedOptions.push(newOption)
                        })
                    }


                    let importantInputValues = {
                        id: input.getAttribute('id'),
                        name: input.name,
                        slug: input.dataset.slug,
                        label: input.dataset.label,
                        originalSlug: input.dataset.originalSlug,
                        type: input.getAttribute('type') || input.tagName.toLowerCase(),
                        value: input.value,
                        options: parsedOptions,
                        checked: input.checked
                    }


                    collectedInputs[input.name].push(importantInputValues)
                })

                this.selectMedcurityBaaNavigation(collectedInputs)
            },

        }
    }
</script>

<style lang="scss">

    #medcurityBaaBody{
        .popper {
            background-color: #007DC1;
            margin-bottom: 5px;
            color: #ffffff;
            padding: 4px 12px;
            border-radius: 7px;
            font-weight: bold;
        }

         /*RESET STYLES FOR CKeditor content. */
        h1,h2,h3,h4,p {
            color: initial;
            font-weight: initial;
            letter-spacing: revert;
        }
        p {
            font-size: initial;
            margin-bottom: revert;
            line-height: revert;
        }
        li p {
            display: block;
            span{
                display: block;
            }
        }
        label {
            color: revert;
            font-size: revert;
            margin-left: 0;
            max-width: 95%;
            vertical-align: middle;
            line-height: 17px;
        }
        input, select{
            height: 28px;
        }
        input[type="radio"]{
            position: relative;
            top: 7px;
        }
        input[type="checkbox"] {
            display: inline-block;
            position: relative;
            top: 9px;
        }
        input[type="checkbox"] + label::before{
            display: none;
        }
        select{
            float: initial;
            display: inline-block;
            position: relative;
            top: 4px;
         }
    }

</style>
